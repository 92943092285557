// node modules
share('rxjs', require('rxjs'));
share('rxjs/operators', require('rxjs/operators'));
share('memoize-one', require('memoize-one'));
share('@microsoft/teams-js', require('@microsoft/teams-js'));

// local modules
share('@shared/web-app', require('./modules/web-app'));

// helper function
export function share (name: string, module: any) {
  System.register(name, [], e => ({ execute: () => { e(module) } }));
}
