import { Subject } from 'rxjs';
// import { ToastMessageData } from '../../react/src/navigation/MainContent/services/Toast.types';

export type ToastTones = 'default' | 'success' | 'info' | 'danger';

export type ToastAction = {
  label: string
  handleAction: () => void
};

export type ToastMessageData = {
  message: string
  ttl?: number
  icon?: string
  tone?: ToastTones
  key?: number
  progress?: number
  action?: ToastAction
  close?: () => void
  hideClose?: boolean
};
export type AppAction = {
  action: 'SHOW_TOAST' | 'UPDATE_TOAST'
  data: ToastMessageData
  key?: string
  callback: any
}

export const sharedAppSubject = new Subject<AppAction>();

export function showToast(input: Omit<AppAction, 'callback'>) {
  return new Promise<void>((resolve, reject) => {
    sharedAppSubject.next({
      ...input,
      callback: resolve
    });
  });
}
